const user =
  window.sessionStorage.getItem("user") &&
  JSON.parse(window.sessionStorage.getItem("user") || "");

export const STATUS_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHENTICATED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
} as const;

export const QUERY_KEYS = {
  GET_MFA_VALUES: "auth0",
  SET_MFA_VALUES: "auth0/",
  USER_SETTING: "/users/settings",
  ALLOWED_VALUES: "allowedValues",
  COLLECTORS: "collectors",
  COLLECTORS_ALL: "collectorsAll",
  COMMENTS: "comments",
  SCIENTIFIC_UNIT: "scientificUnit",
  ESTIMATE_COMMENTS: "estimateComments",
  EMISSION_FACTORS: "emissionFactors",
  EMISSION_FACTOR_BY_SITE: "utility-type-conversion/allsites",
  MISSING_DOCUMENTS: "missingDocuments",
  NOTIFICATIONS: "notifications",
  SITES: "sites",
  SITES_EMISSIONS: "sitesEmissions",
  AUDIT_LOGS: '/audit-log/search',
  SCOPE_SITES_EMISSIONS: "supplies/",
  SCOPE_SITES_POPUP_EMISSIONS: "supplies",
  USERS: "users",
  UTILITY_BILLS: "utilityBills",
  DOCUMENTS: "documents",
  UNIT_CONFIGURATION: "unitConfiguration",
  CONFIG_PARAMETER: "configParameter",
  SITE_EMISSION_DETAIL: "billdetailsbysiteutility",
  DOCUMENT_BILLS: "documents/*/bills",
  USER_SETTINGS: "userSettings",
  ESTIMATES: "estimates",
  REC_CERTIFICATE: "recdocument",
  INTENSITY: "intensity",
  INTENSITYSITE: "intensitysite",
  FILESREPO: "filesrepo",
  RENEWABLE_ENERGY_PURCHASES: "/renewable-energy-purchase",
  APPROVAL: "approvals"
};

export const URL_QUERY_KEYS = {
  PAGE_SIZE: "size",
  PAGE_NUMBER: "page",
};

export const QUERY_PARAMS_KEYS = {
  PAGE_SIZE: "pageSize",
  PAGE_NUMBER: "pageNumber",
  SEARCH: "search",
  UTILITY_TYPE_IDS: "utilityTypeIds",
  SITE_TYPES: "siteTypes",
  BUSINESS_UNITS: "businessUnits",
  COUNTRIES: "countries",
  SCOPE: "scope",
  COUNTRY_REGION: "country_region",
  SITE_IDS: "siteIds",
  ACCOUNT_NUMBERS: "accountNumbers",
  METER_NUMBERS: "meterNumbers",
  STATUSES: "statuses",
  YEAR: "year",
  MONTH: "month",
  EXPANDED_ROWS: "expandedRows",
  SORT_BY: "sortBy",
  SORT_DIRECTION: "sortDirection",
};

export const LOCAL_STORAGE_KEYS = {
  DOCUMENT_SUMMARY_PAGE_SIZE: "DOCUMENT_SUMMARY_PAGE_SIZE",
  DOCUMENT_UPLOAD_PAGE_SIZE: "DOCUMENT_UPLOAD_PAGE_SIZE",
  EMISSION_SUMMARY_SIZE: "EMISSION_SUMMARY_SIZE",
};

export const DATE_FORMAT =
  user && user.dateFormat === "DD-MM-YYYY" ? "D MMM, YYYY" : "MMM D, YYYY";

export const UTC_DATE_FORMAT = user && user.dateFormat;

export const REQUIRED_VALIDATION_MESSAGE = "Field is required";

export const PROMISE_STATE = {
  rejected: "rejected",
  fulfilled: "fulfilled",
} as const;

export const HTTP_METHODS = {
  post: "post",
  get: "get",
  put: "put",
  delete: "delete",
};

export const TABLE_HEADERS = {
  accountNumber: "Account #",
  meterNumber: "Meter #",
  uploader: "Uploader",
  GHG_Emissions: "GHG Emissions"
};

export const AUDIT_LOGS_EVENT: any = {
  INSERT: "The following details have been created: ",
  UPDATE: "The following changes have been made: ",
  DELETE: "The following details have been deleted:",
};

export const AUDIT_LOGS_COLOR: any = {
  INSERT: "#E1EFE6",
  UPDATE: "#E1EFE6",
  DELETE: "#FCE8E7",
};

export const APPLICATION_JSON = "application/json";
export const FORM_DATA = "multipart/form-data";
