import React, { ForwardedRef, forwardRef } from "react";
import { Autocomplete, AutocompleteProps } from "@mui/material";
import { COLORS } from "components/consts";
import { EsgArrowDown } from "assets";
import { TextField, TextFieldProps } from "./text-field";

export const EsgAutocompleteField = forwardRef(
    <
        T,
        Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined,
    >(
        {
            autocompleteProps,
            textFieldProps,
        }: {
            textFieldProps: TextFieldProps;
            autocompleteProps: Omit<
                AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
                "renderInput"
            >;
        },
        ref: ForwardedRef<HTMLInputElement>,
    ) => (
        <Autocomplete
            ref={ref}
            size="small"
            fullWidth
            clearIcon={null}
            popupIcon={<EsgArrowDown />}
            clearOnBlur={false}
            blurOnSelect
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.Romance,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.Romance,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.Romance,
                },
                "& input.MuiInputBase-.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                },
                "& > div > div > div": {
                    height: "36px",
                    borderRadius: "8px",
                    color: "#354950",
                    "& input": {
                        padding: "0 0 0 8px !important",
                    }
                }
            }}
            slotProps={{
                popper: {
                    sx: {
                        "& .MuiAutocomplete-paper": {
                            border: "1px solid",
                            borderColor: COLORS.Romance,
                            borderRadius: "11px",
                        },
                        "& .MuiAutocomplete-option input": {
                            color: COLORS.Romance,
                            fontSize: "16px",
                            borderColor: COLORS.Romance,
                            borderWidth: "1px",
                            minHeight: "26px",
                            borderRadius: "8px",
                            transform: "scale(1.4)",
                        },
                        "& .MuiAutocomplete-option input:focus": {
                            borderColor: COLORS.Romance,  // Focus state styling
                        },
                    }
                }
            }}


            renderInput={(params) => (
                <TextField
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "& fieldset": {
                                border: "1px solid",
                                borderColor: COLORS.Romance,
                            },
                            "&:hover fieldset": {
                                borderColor: COLORS.Romance,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: COLORS.Romance,
                            },
                        },
                        color: COLORS.Romance
                    }}
                    {...params}
                    {...textFieldProps}
                />
            )}
            {...autocompleteProps}
        />
    ),
);
