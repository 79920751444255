import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, {
    ChangeEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import {
    createDocuments,
    documentUploaded,
    getDocuments,
    uploadDocumentToS3,
    uploadSites,
} from "api";

import { UploadOverRideButton } from "components/buttons";

import { useNotify, useSites } from "hooks";

import { QUERY_KEYS } from "consts";

import { updateFileCountContext } from "providers/uploadFileCount-provider";

import { USER_ROLES, useUserRole } from "recoils";

import { Body2Regular } from "components/typography";
import { EsgFileUpload } from "assets";
import { Box } from "@mui/system";
import { useDialogControls } from "../dialog";
import { SiteSelectionDialog } from "../site-selection-dialog";

export const EsgDocumentsUpload: FunctionComponent<{
    isScope3: boolean;
    isLoading1?: boolean;
}> = ({ isScope3 = false, isLoading1 }) => {
    const notify = useNotify();
    const userRole = useUserRole();
    const queryClient = useQueryClient();
    const { mutate: create } = useMutation(createDocuments);
    const { mutateAsync: uploadToS3 } = useMutation(uploadDocumentToS3);
    const { mutateAsync: uploaded } = useMutation(documentUploaded);
    const { data: sites } = useSites();

    const sitesDialogControls = useDialogControls();
    const [selectedSite, setSelectedSite] = useState<null | number>(null);
    const [uploadCount, setUploadCount] = useState<any>(0);
    const [uploaddiseble, setUploadsdiseble] = useState(false);
    const [showBox, setShowBox] = useState(false);

    const handlClick = () => {
        setShowBox((prev) => !prev);
    };

    const { updateUploadedCount, updateTotalCount } = useContext(
        updateFileCountContext,
    );

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    let isOneSite = false;

    if (sites !== undefined) {
        isOneSite = sites?.length > 1;
    }
    const onSiteSelect = (id: number) => {
        sitesDialogControls.close();
        setSelectedSite(id);
        fileInputRef?.current?.click();
    };

    const onUploadButtonClick = () => {
        if (isOneSite && !userRole.isAdmin) {
            // sitesDialogControls.open();
            setSelectedSite(0);
            fileInputRef?.current?.click();
        } else if (sites?.length === 1 && !userRole.isAdmin) {
            setSelectedSite(0);
            fileInputRef?.current?.click();
        } else {
            setSelectedSite(0);
            fileInputRef?.current?.click();
        }
    };

    useEffect(() => {
        updateUploadedCount(uploadCount);
    }, [uploadCount, updateUploadedCount]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        let uploadedFilesCount = 0;
        updateTotalCount(files.length);
        setUploadsdiseble(true)
        create(
            {
                fileNames: files.map((file) => file.name),
                siteId: selectedSite ?? 1,
            },
            {
                onSuccess: (
                    documents: {
                        s3uploadUrl: string;
                        document: { uuid: string; originalFileName: string };
                    }[],
                ) => {
                    queryClient.invalidateQueries([QUERY_KEYS.UTILITY_BILLS]).then(() => {
                        Promise.allSettled(
                            documents.map((document) =>
                                uploadToS3({
                                    url: document.s3uploadUrl,
                                    file: files.find(
                                        (file) => file.name === document.document.originalFileName,
                                    ) as File,
                                })
                                    .then(() => uploaded(document.document.uuid))
                                    .then((res) => {
                                        if (res.data[0].status === "ERROR") {
                                            notify.error(
                                                `Failed to upload file: ${document.document.originalFileName}!`,
                                            );
                                        }
                                        if (res.data[0].status === "PROCESSING") {
                                            setUploadCount(uploadedFilesCount + 1);
                                            uploadedFilesCount += 1;
                                        }
                                    })
                                    .catch(() => {
                                        // TODO cleanup document if failed to upload
                                        notify.error(
                                            `Failed to upload file: ${document.document.originalFileName}!`,
                                        );
                                    }),
                            ),
                        ).then(() => {
                            if (uploadedFilesCount === files.length) {
                                notify.success(`Successfully uploaded all files!`);
                                setTimeout(() => {
                                    updateUploadedCount(0);
                                    updateTotalCount(0);
                                }, 2000);
                            } else {
                                notify.success(
                                    `Successfully uploaded files: ${uploadedFilesCount}/${files.length}`,
                                );
                                setTimeout(() => {
                                    updateUploadedCount(0);
                                    updateTotalCount(0);
                                }, 2000);
                            }
                            queryClient.invalidateQueries([QUERY_KEYS.UTILITY_BILLS]);
                            queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS]);
                            e.target.value = "";
                            getDocuments({})
                        });
                    });
                },
                onError() {
                    notify.error("Failed to upload files!");
                    e.target.value = "";
                },
            },
        );
    };

    useEffect(() => {
        if (uploaddiseble) {
            setUploadsdiseble(false)

        } else { /* empty */ }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading1])

    return (
        <>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "200px", position: "relative" }}>
                <Button
                    sx={{
                        borderRadius: 2,
                        color: "white",
                        gap: 1,
                        width: "200px",
                        height: "36px",
                       
                    }}
                    variant="contained"
                    startIcon={<EsgFileUpload />}
                    onClick={handlClick}>
                    <Body2Regular>Upload Data</Body2Regular>
                </Button>

                {showBox && (
                    <Box
                        sx={{
                            width: "200px",
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            mt: 1,
                            p: 0.5,
                            border: "1px solid #C1C1C1",
                            borderRadius: "8px",
                            backgroundColor: "#FBFBFB",
                            zIndex: 10,
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {!isScope3 && (
                            <>
                                <input
                                    ref={fileInputRef}
                                    hidden
                                    accept="application/pdf"
                                    multiple
                                    type="file"
                                    onChange={onChange}
                                />
                                <Button
                                    variant="contained"
                                    startIcon={<PictureAsPdfIcon />}
                                    title="Upload pdf"
                                    fullWidth
                                    onClick={onUploadButtonClick}
                                    disabled={!sites?.length || userRole.isAuditor || uploaddiseble}
                                >
                                    Upload pdf
                                </Button>
                            </>
                        )}

                        <UploadOverRideButton
                            fullWidth
                            variant="contained"
                            label="Upload Excel Data"
                            uploadApiCall={uploadSites}
                            queryKeyToInvalidate={QUERY_KEYS.DOCUMENTS}
                            availableToRoles={[USER_ROLES.ADMIN, USER_ROLES.SITE_MANAGER]}
                            extraReqParams={[["configuration", "false"]]}
                            isScope3={isScope3}
                            marginTop="4px"
                            apiResponse={() => { }}
                            uploaddiseble={uploaddiseble}
                            setUploadsdiseble={setUploadsdiseble}
                        />
                    </Box>
                )}
            </Box>

            {isOneSite && (
                <SiteSelectionDialog
                    isDialogOpen={sitesDialogControls.isOpen}
                    onDialogClose={sitesDialogControls.close}
                    onSiteSelect={onSiteSelect}
                />
            )}
        </>
    );
};
