
import { Box, Button, Grid } from "@mui/material";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, memo, useEffect, useRef, useState } from "react";

import {
  UserDTO,
  deleteUser,
  deleteUserSite,
  reActivateUser,
  updateUserRole,
  updateUserSite,
} from "api";

import { TrashIcon } from "assets";

import {
  AutocompleteField,
  Avatar,
  Label14SemiBold,
  MultiselectControlledField,
} from "components";

import { useNotify, useSitesMultiselect } from "hooks";

import { QUERY_KEYS } from "consts";

import {
  USER_ROLES_NAMES,
  USER_ROLE_COLOR,
  UserRole,
  getUserRole,
} from "recoils";

import { getInitials } from "utils";

import { ROLE_SELECT_OPTIONS } from "./consts";

interface UserSiteParams {
  userId: number;
  siteId: number;
}

export const User: FunctionComponent<{
  userData: UserDTO;
  getFilter: UserRole | null;
}> = memo(({ userData, getFilter }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteUsers } = useMutation(deleteUser);
  const { mutateAsync: reactivateUsers } = useMutation(reActivateUser);
  const { mutateAsync: updateRole } = useMutation(updateUserRole);

  const notify = useNotify();
  const [roleSelectValue, setRoleSelectValue] = useState<null | string>(null);
  const [loggedUser, setLoggedUser] = useState<UserDTO>();
  const hasFetchedUser = useRef(false);
  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useSitesMultiselect({ withQueryParams: false });

    const useUpdateUserSite = (): UseMutationResult<
    unknown,
    unknown,
    UserSiteParams  
  > => useMutation(({ userId, siteId }: any) => updateUserSite(userId, siteId));
  
  const useDeleteUserSite = (): UseMutationResult<
    unknown,
    unknown,
    UserSiteParams
  > => useMutation(({ userId, siteId }: any) => deleteUserSite(userId, siteId));


  const { mutateAsync: updateUserSiteSetting } = useUpdateUserSite();
  const { mutateAsync: deleteUserSiteSetting } = useDeleteUserSite();

  const handleUpdateUserSite = async (userId: number, siteId: number, user:  any) => {

    try {
      await updateUserSiteSetting({ userId, siteId });
      notify.success(`Site update successfully for ${user.email}!`);
    } catch (error) {
      console.error('Failed to update user site:', error);
    }
  };

  const handleDeleteUserSite = async (userId: number, siteId: number, user:  any) => {
    try {
      await deleteUserSiteSetting({ userId, siteId });
      notify.success(`Site update successfully for ${user.email}!`);
    } catch (error) {
      console.error('Failed to delete user site:', error);
    }
  };

  const onDeleteUser = (user: UserDTO) => {
    deleteUsers(user.id, {
      onError: () =>
        notify.error(
          `Some error has happen while deleting user ${user.email}!`,
        ),
      onSuccess: () => {
        notify.success(`User successfully deleted ${user.email}!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  const onReActivateUser = (user: UserDTO) => {
    reactivateUsers(user.id, {
      onError: () =>
        notify.error(`Some error has happen while re-activating the user!`),
      onSuccess: () => {
        notify.success(`User successfully Enabled!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
  
    if (storedUser) {
      setLoggedUser(JSON.parse(storedUser));
    }
  
    hasFetchedUser.current = true;
  }, []); 
  
  

  const disableDelete = () => {
    if (loggedUser !== undefined) {
      if (userData.id === loggedUser.id) return true;
    }
    return false;
  };

  const onUpdateUser = (user: any) => {
    const body = {
      id: user?.id,
      roles: user?.roles,
      siteIds: user?.siteIds
    };

    updateRole(body, {
      onError: () =>
        notify.error(
          `Some error has happen while updating user ${user.email}!`,
        ),
      onSuccess: () => {
        notify.success(`User successfully updated ${user.email}!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  const [previousSelectedValues, setPreviousSelectedValues] = useState(sitesOptions.filter((option) => userData?.sites?.includes(option.value as number)));

  const handleSelectionChange = (newSelectedValues: any) => {
    const addedValues = newSelectedValues.filter(
      (val: any) => !previousSelectedValues.some((prev: any) => prev.value === val.value)
    );
    const removedValues = previousSelectedValues.filter(
      (prev: any) => !newSelectedValues.some((val: any) => val.value === prev.value)
    );

    addedValues.forEach((value: any) => {
      handleUpdateUserSite(userData?.id, value?.value, userData)
    });

    removedValues.forEach((value: any) => {
      handleDeleteUserSite(userData?.id, value?.value, userData)
    });

    setPreviousSelectedValues(newSelectedValues);
    setSelectedSites(newSelectedValues);
  };

  return (
    <>
      <Grid item xs={!(getFilter === "enabled") ? 4 : 8}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Grid xs={6}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {" "}
              {userData.firstName} {userData.lastName}
            </span>
          </Grid>
          <Grid xs={2}>
            <Avatar
              sx={{
                bgcolor:
                  USER_ROLE_COLOR[
                  getUserRole(userData.admin, userData.auditor)
                  ],
                alignItems: "center",
              }}
            >
              {getInitials(`${userData.firstName} ${userData.lastName}`)}
            </Avatar>
          </Grid>
          <Grid xs={4}>
            <Label14SemiBold>{userData.email}</Label14SemiBold>
          </Grid>
        </Box>
      </Grid>
      {userData.enabled && (
        <>
          <Grid item xs={4} style={{ width: "65%", paddingLeft: "180px" }}>
            <AutocompleteField
              textFieldProps={{}}
              autocompleteProps={{
                value:
                  roleSelectValue ||
                  USER_ROLES_NAMES[
                  getUserRole(userData.admin, userData.auditor)
                  ],
                onChange: (_, role) => {
                  if (role) {
                    const payload = {
                      id: userData?.id,
                      siteIds: userData?.sites,
                      roles: [String(role)?.replace(" ","_")],
                      email: userData?.email
                    }
                    onUpdateUser(payload);
                  }

                  setRoleSelectValue(role as string);
                },
                options: ROLE_SELECT_OPTIONS,
              }}
            />
          </Grid>

          <Grid item xs={4} style={{ width: "65%", display: "flex" }}>
            <MultiselectControlledField
              label=""
              disabled={isSitesLoading}
              selectedValues={
                selectedSites.length > 0
                  ? selectedSites
                  : sitesOptions.filter((option) =>
                    userData?.sites?.includes(option.value as number),
                  )
              }
              setSelectedValues={(data) => {
                handleSelectionChange(data)
              }}
              options={sitesOptions}
            />
            <Button
              sx={{ padding: 0 }}
              onClick={() => onDeleteUser(userData)}
              disabled={disableDelete()}
            >
              <TrashIcon />
            </Button>
          </Grid>
        </>
      )}
      {!userData.enabled && (
        <Box>
          <Button
            sx={{
              marginLeft: "6px",
              marginTop: "20px",
              backgroundColor: "lightgray",
            }}
            onClick={() => onReActivateUser(userData)}
          >
            Re-Activate
          </Button>
        </Box>
      )}
    </>
  );
});