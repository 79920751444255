import { Button, Divider, FormControl, IconButton, MenuItem, Modal, Select } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation } from "@tanstack/react-query";
import { getApprovalDATA, getApprovalDATAById, getFiscalYearSettings, undoApprovalLock, undoApprovalSignOff } from "api";
import { EsgArrowDown, EsgArrowDownWhite, LockedIcon, PlusIcon, SignedOffIcon } from "assets";
import { COLORS, EsgPrimaryLabel, MarginWrapper, PrimaryLabel, CloseButton, EMPTY_CELL_VALUE, Label14Medium, Tooltip, Body2Regular, TYPOGRAPHY_COMMON_STYLES } from "components";
import { useNotify, useUtilityBillsYears } from "hooks";
import MaterialReactTable, { MRT_RowSelectionState } from "material-react-table";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { getFirstAndLastDateOfFiscalYear, getLoggedUserData, isNullOrUndefined } from "utils";

import { ApprovalsModal } from "./components";


export const ApprovalPage = () => {

  const currentYear = new Date().getFullYear();

  // ---- States ----
  const [fiscalYear, setFiscalYear] = useState(`${currentYear} Fiscal Year`);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [showApprovalBox, setShowApprovalBox] = useState(false);
  const [fiscalYearDate, setFiscalYearDate] = useState<any>({});
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );
  const [signedOffIds, setSignedOffIds] = useState<number[]>([]);
  const [lockIds, setLockIds] = useState<number[]>([]);

  // ---- API's ----
  const { data, mutateAsync: fetchApprovalData } = useMutation(getApprovalDATA);
  const {  mutateAsync: unlock } = useMutation(undoApprovalLock);
  const {  mutateAsync: undoSignOff } = useMutation(undoApprovalSignOff);
  const { data: availableYears } = useUtilityBillsYears();


  // ---- Const ----
  const startDatePayload = fiscalYearDate?.firstDate;
  const endDatePayload = fiscalYearDate?.lastDate;
  const nextYear = currentYear + 1;
  const notify = useNotify();
  const yearValue = parseInt(fiscalYear.replace("Fiscal Year", ""), 10) as number;
  const fiscalYears = availableYears && [
    ...availableYears.map((yearItem: any) => `${yearItem} Fiscal Year`),
    `${nextYear} Fiscal Year`,
  ];

  // ---- Page Functions ----
  const getMonthsfromSettings = () => {
    getFiscalYearSettings()
      .then((fiscalYearSettings: any) => {
        const startMonthValue = fiscalYearSettings.find(
          (setting: any) => setting.name === "company_month_start",
        )?.configValue;
        const endMonthValue = fiscalYearSettings.find(
          (setting: any) => setting.name === "company_month_end",
        )?.configValue;
        setStartMonth(startMonthValue);
        setEndMonth(endMonthValue);
      })
      .catch((error) => {
        console.error("Error fetching fiscal year settings:", error);
      });
  };

  const setApprovalAction = (value: string, id: any) => {
    if (value === "SIGN_OFF") {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} id={id}>
          <SignedOffIcon color="#0E25D5"/>
          <Label14Medium id={id} sx={{ color: COLORS.Siggned_Off_Color }}>
            Signed Off
          </Label14Medium>
        </Box>
      );
    }
    if (value === "LOCK") {
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} id={id}>
          <LockedIcon color="#1D1D1D"/>
          <Label14Medium id={id} sx={{ color: COLORS.Locked_Color }}>
            Locked
          </Label14Medium>
        </Box>
      );
    }
  
    // Default return value
    return null;
  };

  const handleApprovalClick = () => {
    setShowApprovalBox((prev) => !prev);
  };

  const handlePopupClick = (statusLabel: string) => {
    setStatus(statusLabel)
    setApprovalModalOpen(true);
  }

  const handleApprovalModalClose = () => {
    setApprovalModalOpen(false);
    fetchApprovalData({startDatePayload, endDatePayload})
  }

  const handleUndoSignOffClick = () => {
    undoSignOff(signedOffIds?.join(","), {
      onError: () =>
        notify.error(
          `Some error has happen while performing data Undo Signoff!`,
        ),
      onSuccess: () => {
        notify.success(`Undo Signoff Successfully!`);
        fetchApprovalData({startDatePayload, endDatePayload})
      },
    })
  }

  const handleUnLockClick = () => {
    unlock(lockIds?.join(","), {
      onError: () =>
        notify.error(
          `Some error has happen while performing data Unlocking!`,
        ),
      onSuccess: () => {
        notify.success(`data Unlocked Successfully!`);
        fetchApprovalData({startDatePayload, endDatePayload})
      },
    })
  }

  const handleUndoAllApprovalClick = () => {
    if (lockIds) {
      unlock(lockIds?.join(","))
    }
    if (signedOffIds) {
      undoSignOff(signedOffIds?.join(","))
    }
  }

  // ---- Use Effects ----
  useEffect(() => {
    getMonthsfromSettings();
  });

  useEffect(() => {
    if (startDatePayload && endDatePayload && startDatePayload! !== "Invalid Date" && endDatePayload! !== "Invalid Date") {
      fetchApprovalData({startDatePayload, endDatePayload})
    }
  },[fetchApprovalData, startDatePayload, endDatePayload])

  useEffect(() => {
      const dates = getFirstAndLastDateOfFiscalYear(
        yearValue,
        startMonth,
        endMonth,
      );
      setFiscalYearDate(dates);
    }, [yearValue, startMonth, endMonth]);

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection).map(Number);

    const filteredSignedOff = data?.data
      .filter((item: any) => selectedIds.includes(item.id) && item.action === "SIGN_OFF")
      .map((item: any) => item.id);

    const filteredLock = data?.data
      .filter((item: any) => selectedIds.includes(item.id) && item.action === "LOCK")
      .map((item: any) => item.id);

    setSignedOffIds(filteredSignedOff);
    setLockIds(filteredLock);
  }, [rowSelection, data?.data]);

  // ---- Table Columns ---
  const detailColumn = [
    {
      header: "Site",
      Header: <Box sx={{ paddingLeft: "3px" }}>Site</Box>,
      accessorKey: "siteName",
      enableSorting: false,
      size: 200,
    },
    {
      header: "Applicable Scope 1 Usage",
      Header: <Box sx={{ paddingLeft: "3px" }}>Applicable Scope 1 Usage</Box>,
      accessorKey: "applicableScope1Usage",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.applicableScope1UsageUnit
        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
    },
    {
      header: "Applicable Scope 2 Usage",
      Header: <Box sx={{ paddingLeft: "3px" }}>Applicable Scope 2 Usage</Box>,
      accessorKey: "applicableScope2Usage",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.applicableScope2UsageUnit
         if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
    },
    {
      header: "Applicable Scope 1 Emissions",
      Header: <Box sx={{ paddingLeft: "3px" }}>Applicable Scope 1 Emissions</Box>,
      accessorKey: "applicableScope1Emissions",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.applicableScope1EmissionsUnit
         if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
      
    },
    {
      header: "Applicable Scope 2 Emissions",
      Header: <Box sx={{ paddingLeft: "3px" }}>Applicable Scope 2 Emissions</Box>,
      accessorKey: "applicableScope2Emissions",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.applicableScope2EmissionsUnit
         if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
      
    },
    {
      header: "Global Scope 1 Usage",
      Header: <Box sx={{ paddingLeft: "3px" }}>Global Scope 1 Usage</Box>,
      accessorKey: "globalScope1Usage",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.globalScope1UsageUnit
         if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
      
    },
    {
      header: "Global Scope 2 Usage",
      Header: <Box sx={{ paddingLeft: "3px" }}>Global Scope 2 Usage</Box>,
      accessorKey: "globalScope2Usage",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.globalScope2UsageUnit
         if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
      
    },
    {
      header: "Global Scope 1 Emissions",
      Header: <Box sx={{ paddingLeft: "3px" }}>Global Scope 1 Emissions</Box>,
      accessorKey: "globalScope1Emissions",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.globalScope1EmissionsUnit
         if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
      
    },
    {
      header: "Global Scope 2 Emissions",
      Header: <Box sx={{ paddingLeft: "3px" }}>Global Scope 2 Emissions</Box>,
      accessorKey: "globalScope2Emissions",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue() 
        const unit = row.original.globalScope2EmissionsUnit
         if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
         <>{`${value} ${unit}`}</>
        );
      },
      enableSorting: false,
      size: 250,
      
    },
  ]

  const columns = [
    {
      header: "Action",
      Header: <Box sx={{ paddingLeft: "3px" }}>Action</Box>,
      accessorKey: "action",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue()

        const [open, setOpen] = useState(false);
        const [detailData, setDetailData] = useState<any>([]);

        const handleClose = () => setOpen(false);

        const style = {
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          zIndex: 999,
        };

        const handleViewClick = async (e: any) => {
          const approvalId = e.target.id
          if (approvalId) {
          const details = await getApprovalDATAById(approvalId);
          setDetailData(details?.data)
          }
          setOpen(true);
        };

        const tableData = detailData?.approvalSiteDetails || []
        const id = row.original?.id
       

        return (
          <>
          <Box id={row.original?.id} sx={{ textAlign: "right", cursor: "pointer" }}>
            <Button id={row.original?.id} onClick={(e: any) => handleViewClick(e)}>
              {setApprovalAction(value, id)}
            </Button>
          </Box>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {/* Title */}
                <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: "12px", alignItems: 'center' }}>
                <Box sx={{ mx: 3, mt:2, display: 'flex', gap: 5 }}>
                      <Box sx={{ gap: 1, display: 'flex' }}>
                        <PrimaryLabel>Action</PrimaryLabel>
                        <EsgPrimaryLabel sx={{color: 'black'}}>{setApprovalAction(detailData?.action, id)}</EsgPrimaryLabel>
                      </Box>
                      <Box sx={{ gap: 1, display: 'flex' }}>
                        <PrimaryLabel>Name</PrimaryLabel>
                        <EsgPrimaryLabel sx={{color: 'black'}}>{detailData?.user}</EsgPrimaryLabel>
                      </Box>
                      <Box sx={{ gap: 1, display: 'flex' }}>
                        <PrimaryLabel>Time Stamp</PrimaryLabel>
                        <EsgPrimaryLabel sx={{color: 'black'}}>{moment(detailData?.createDate).format('MM/DD/YYYY HH:mm:ss')}</EsgPrimaryLabel>
                      </Box>
                      <Box sx={{ gap: 1, display: 'flex' }}>
                        <PrimaryLabel>Period Start</PrimaryLabel>
                        <EsgPrimaryLabel sx={{color: 'black'}}>{moment(detailData?.periodStart as string).format('MM/DD/YYYY')}</EsgPrimaryLabel>
                      </Box>
                      <Box sx={{ gap: 1, display: 'flex' }}>
                        <PrimaryLabel>Period End</PrimaryLabel>
                        <EsgPrimaryLabel sx={{color: 'black'}}>{moment(detailData?.periodEnd as string).format('MM/DD/YYYY')}</EsgPrimaryLabel>
                      </Box>
                    </Box>
                  <IconButton onClick={() => setOpen(false)}>
                    <CloseButton />
                  </IconButton>
                </div>
                {/* Grey Header */}
                  <Box sx={{ height: 80, my: '12px' }}>
                    <Divider/>
                    <Box  sx={{ mx: 3 }}>
                    <MaterialReactTable
                      enableDensityToggle={false}
                      enableFullScreenToggle={false}
                      enableRowSelection={false}
                      data={tableData}
                      enablePagination
                      columns={detailColumn}
                      enableRowVirtualization
                      enableStickyHeader
                      enableColumnFilters
                      initialState={{ density: 'compact' }}
                      muiTableContainerProps={{
                        sx: {
                          "& thead tr:first-of-type th:first-of-type": {
                            paddingLeft: "20px",
                          },
                          "& tbody tr td:first-of-type": {
                            paddingLeft: "20px",
                          },
                          "& tbody tr:first-of-type td > p": {
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                          },
                          height: "calc(100vh - 355px)",
                          border: "1px solid #D9D9D9",
                          borderRadius: "10px",
                          minWidth: "100%",
                          width: "100%",
                          maxWidth: "100%",
                          "@media (min-width: 600px)": {
                            width: "100%",
                          },
                          "@media (min-width: 960px)": {
                            width: "1100px",
                          },
                          "@media (min-width: 1280px)": {
                            width: "1100px",
                          },
                          "@media (min-width: 1440px)": {
                            width: "1100px",
                          },
                          "@media (min-width: 1680px)": {
                            width: "1300px",
                          },
                          "@media (min-width: 2400px)": {
                            width: "100%",
                          },
                        },
                      }}
                      muiTablePaperProps={{
                        sx: {
                          borderRadius: "10px",
                          boxShadow: "none",
                        },
                      }}
                      muiTopToolbarProps={{
                        sx: {
                          backgroundColor: 'white',
                          '& .MuiIconButton-root': {
                            display: 'none',
                          },
                        },
                      }}
                      muiBottomToolbarProps={{
                        sx: {
                          display: "none",
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: {
                          color: COLORS.Romance,
                          lineHeight: "30px",
                          "& .MuiBadge-root": {
                            display: "none",
                          },
                        },
                      }}
                      muiTableHeadRowProps={{
                        sx: {
                          "& > th": {
                            paddingTop: 1,
                            paddingBottom: 1,
                          },
                          height: "45px",
                          backgroundColor: "#F8FAFC",
                          color: COLORS.Romance
                        },
                      }}
                      muiTableBodyRowProps={{
                        sx: {
                          height: "45px",
                          backgroundColor: "transparent",
                        },
                      }}
                      getRowId={(rowData: any) => rowData.id}
                    />
                    </Box>
                  </Box>
                {/* Scrollable Component  */}
                
              </Box>
            </Modal>
          </div>
          </>
        );
      },
      enableSorting: false,
      size: 125,
    },
    {
      header: "Name",
      Header: <Box sx={{ paddingLeft: "3px" }}>Name</Box>,
      accessorKey: "user",
      Cell: ({ cell }: any) => {
        const value = cell.getValue() 

        return (
          <>{value}</>
        );
      },
      enableSorting: false,
      size: 125,
    },
    {
      header: "Time Stamp",
      Header: <Box sx={{ paddingLeft: "3px" }}>Time Stamp</Box>,
      accessorKey: "createDate",
      Cell: ({ cell }: any) => {
        const value = cell.getValue() 

        return (
          <>{moment(value).format('MM/DD/YYYY HH:mm:ss')}</>
        );
      },
      enableSorting: false,
      size: 125,
    },
    {
      header: "Site Name",
      Header: <Box sx={{ paddingLeft: "3px" }}>Site Name</Box>,
      accessorKey: "sites",
      Cell: ({ cell }: any) => {
        const value = cell.getValue() 
        const showTooltipData = value.map((t: any) =>  (<li>{t}</li>)
        )
        return (
          <Tooltip title={showTooltipData}>
            <Box
              sx={{
                alignItems: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >{value.join(", ")}</Box>
          </Tooltip>
        );
      },
      enableSorting: false,
      size: 200,
    },
    {
      header: "Period Start",
      Header: <Box sx={{ paddingLeft: "3px" }}>Period Start</Box>,
      accessorKey: "periodStart",
      Cell: ({ cell }: any) => {
        const value = cell.getValue() 

        return (
          <>{moment(value).format('MM/DD/YYYY')}</>
        );
      },
      enableSorting: false,
      size: 125,
    },
    {
      header: "Period End",
      Header: <Box sx={{ paddingLeft: "3px" }}>Period End</Box>,
      accessorKey: "periodEnd",
      Cell: ({ cell }: any) => {
        const value = cell.getValue() 

        return (
          <>{moment(value).format('MM/DD/YYYY')}</>
        );
      },
      enableSorting: false,
      size: 125,
    },
  ];

  const loggedUserData = getLoggedUserData();


  const isDataProvider = loggedUserData?.roles?.includes("DATA_PROVIDER")
  const isDataController = loggedUserData?.roles?.includes("DATA_CONTROLLER")
  const isAuditor = loggedUserData?.roles?.includes("AUDITOR_EMISSIONS")
  const isSustainabilityManager = loggedUserData?.roles?.includes("SUSTAINABILITY_MANAGER")

  return (
      <MarginWrapper>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box>
          <FormControl sx={{ width: "260px" }}>
            <Select
              displayEmpty
              sx={{
                width: "260px",
                height: "36px",
                borderRadius: "8px",
                fontFamily: "Inter",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "& .MuiSelect-icon": {
                  fontSize: "28px", // Increase size
                  color: COLORS.Mainblue, // Adjust color
                  right: "12px", // Position it correctly
                  top: "50%",
                  transform: "translateY(-50%)", // Ensure vertical centering
                  position: "absolute",
                  pointerEvents: "none",
                },
                "& .MuiSelect-select": {
                  fontSize: "14px",
                  color: COLORS.Romance,
                  fontWeight: 500,
                },
              }}
              labelId="fiscal-year-label"
              id="fiscal-year-select"
              value={fiscalYear}
              onChange={(event) => setFiscalYear(event.target.value as string)}
              placeholder="Fiscal Year"
              renderValue={(selected) => {
                if (!selected) {
                  return <Body2Regular sx={{ color: COLORS.Romance, mt: "1px" }}>Select Fiscal Year</Body2Regular>;
                }
                return selected;
              }}
              IconComponent={(props) => (
                <Box sx={{ pointerEvents: "none", display: "flex", alignItems: "center", mr: 2 }}>
                  <EsgArrowDown {...props} />
                </Box>
              )}
            >
              {fiscalYears &&
                fiscalYears.map((year: any) => (
                  <MenuItem key={year} value={year} sx={{
                    fontWeight:
                      TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                    fontSize: 14,
                    lineHeight: "18px",
                    color: "#90959E",
                    "&:hover": {
                      backgroundColor: "#F6F8FA",
                      borderRadius: 5,
                      color: COLORS.Romance,
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#F6F8FA",
                      borderRadius: 5,
                      color: COLORS.Romance,
                    },
                  }}>
                    {year}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{display: "flex", gap: 1}}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "220px", position: "relative" }}>
            {/* Approval Actions Button */}
            <Button
              sx={{
                borderRadius: 2,
                color: "white",
                width: "220px",
                height: "36px"
              }}
              variant="contained"
              endIcon={<EsgArrowDownWhite />}
              startIcon={<PlusIcon />}
              disabled={isDataProvider || isAuditor || isSustainabilityManager}
              onClick={handleApprovalClick}>
              <Body2Regular>Add Approval Actions</Body2Regular>
            </Button>

            {/* Conditional Box */}
            {showApprovalBox && (
              <Box
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  mt: 1,
                  p: 1,
                  border: "1px solid #C1C1C1",
                  borderRadius: "8px",
                  backgroundColor: "#FBFBFB",
                  zIndex: 10,
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* Sign off Button */}
                <Button
                  sx={{
                    borderRadius: 2,
                    color: "white",
                    textTransform: "none",
                    mb: "8px",
                    width: "200px",
                    height: "36px",
                  }}
                  variant="contained"
                  startIcon={<SignedOffIcon color="white" />}
                  onClick={() => handlePopupClick("signoff")}
                >
                  <Body2Regular>Sign off</Body2Regular>
                </Button>

                {/* Lock Button */}
                <Button
                  sx={{
                    borderRadius: 2,
                    color: "white",
                    textTransform: "none",
                    width: "200px",
                    height: "36px",
                  }}
                  onClick={() => handlePopupClick("lock")}
                  variant="contained"
                  disabled={isDataController}
                  startIcon={<LockedIcon color="white" />}
                >
                  <Body2Regular>Lock</Body2Regular>

                </Button>
              </Box>
            )}
          </Box>
          {(signedOffIds?.length > 0 && lockIds?.length === 0) && <Button
            sx={{
              borderRadius: 2,
              color: "white",
              textTransform: "none",
              mb: "8px",
              width: "180px",
              height: "36px",
              bgcolor: "#0E25D5"
            }}
            variant="contained"
            startIcon={<SignedOffIcon color="white" />}
            onClick={handleUndoSignOffClick}
            disabled={isDataProvider || isAuditor || isSustainabilityManager}
          >
            <Body2Regular>Undo Sign-off</Body2Regular>
          </Button>}
          {(lockIds?.length > 0 && signedOffIds?.length === 0) && <Button
            sx={{
              borderRadius: 2,
              color: "white",
              textTransform: "none",
              mb: "8px",
              width: "180px",
              height: "36px",
              bgcolor: "#000"
            }}
            variant="contained"
            startIcon={<SignedOffIcon color="white" />}
            onClick={handleUnLockClick}
            disabled={isDataProvider || isAuditor || isSustainabilityManager}
          >
            <Body2Regular>Unlock</Body2Regular>
          </Button>}
          {(lockIds?.length > 0 && signedOffIds?.length > 0) && <Button
            sx={{
              borderRadius: 2,
              color: "white",
              textTransform: "none",
              mb: "8px",
              width: "280px",
              height: "36px",
              bgcolor: "#DD1515"
            }}
            variant="contained"
            startIcon={<SignedOffIcon color="white" />}
            onClick={handleUndoAllApprovalClick}
            disabled={isDataProvider || isAuditor || isSustainabilityManager}
          >
            <Body2Regular>Undo All Selected Approvals</Body2Regular>
          </Button>}
        </Box>
      </Box>
        {data?.data && <Box sx={{ mt: 3 }}>
          <MaterialReactTable
            enableTopToolbar={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableRowSelection
            onRowSelectionChange={setRowSelection}
            data={data?.data}
            enablePagination
            columns={columns}
            enableRowVirtualization
            enableStickyHeader
            enableColumnFilters
            initialState={{ density: 'compact' }}
            muiTableContainerProps={{
              sx: {
                "& thead tr:first-of-type th:first-of-type": {
                  paddingLeft: "20px",
                },
                "& tbody tr td:first-of-type": {
                  paddingLeft: "20px",
                },
                "& tbody tr:first-of-type td > p": {
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                },
                height: "calc(100vh - 255px)",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 960px)": {
                  width: "1100px",
                },
                "@media (min-width: 1280px)": {
                  width: "1100px",
                },
                "@media (min-width: 1440px)": {
                  width: "1100px",
                },
                "@media (min-width: 1680px)": {
                  width: "1300px",
                },
                "@media (min-width: 2400px)": {
                  width: "100%",
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                backgroundColor: 'white',
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                color: COLORS.Romance,
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
                height: "auto",
                color: COLORS.Romance
              },
            }}
          muiTableBodyRowProps={ {
              sx: {
                cursor: "pointer",
                backgroundColor: "white",
              
            }
          }}
          
            getRowId={(row: any) => row.id}
            state={{ rowSelection }}
          />
        </Box>}
        <ApprovalsModal
        isOpen={approvalModalOpen}
        onClose={handleApprovalModalClose}
        status={status}
        />
      </MarginWrapper>  
  );
};

export default ApprovalPage;
