import { useQuery } from "@tanstack/react-query";

import {
  DocumentByIdDTO,
  DocumentsDTO,
  DocumentsQuery,
  ScopePopup,
  getDocumentBills,
  getDocumentById,
  getDocumentScope,
  getDocumentSupplieById,
  getDocuments,
  getUseUserSettings,
} from "api";

import { QUERY_KEYS } from "consts";

import { useState } from "react";
import { DOCUMENTS_REFETCH_INTERVAL } from "./consts";
import { OptionalRefetchIntervalAndRetry } from "./types";

// export const useDocuments = ({
//   refetchInterval = DOCUMENTS_REFETCH_INTERVAL,
//   retry = false,
//   ...query
// }: DocumentsQuery & OptionalRefetchIntervalAndRetry) =>
//   useQuery<DocumentsDTO>(
//     [QUERY_KEYS.DOCUMENTS, query],
//     () => getDocuments(query),
//     {
//       refetchInterval,
//       retry,
//     },
//   );
export const useDocuments = ({
  refetchInterval = DOCUMENTS_REFETCH_INTERVAL,
  retry = false,
  ...query
}: DocumentsQuery & OptionalRefetchIntervalAndRetry) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading1, setIsLoading1] = useState(true);
  const { data, error } = useQuery<DocumentsDTO>(
    [QUERY_KEYS.DOCUMENTS, query],
    async () => {
      setIsLoading1(true)
      try {
        const result = await getDocuments(query);
        setIsLoading1(false); 
        return result;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error) {
        setIsLoading1(false); 
        throw error;
      }
    },
    {
      refetchInterval,
      retry,
      onSuccess: () => setIsLoading1(false), 
      onError: () => setIsLoading1(false),
    },
  );
  return { data, isLoading1, error };
};

export const useDocumentScope = ({
  refetchInterval = DOCUMENTS_REFETCH_INTERVAL,
  retry = false,
  ...query
}: DocumentsQuery & OptionalRefetchIntervalAndRetry) =>
  useQuery<any>([QUERY_KEYS.DOCUMENTS, query], () => getDocumentScope(query), {
    refetchInterval,
    retry,
  });

export const useUserSettings = () =>
  useQuery<any>([QUERY_KEYS.USER_SETTING], () => getUseUserSettings());

export const useDocumentById = (id: string) =>
  useQuery<DocumentByIdDTO>([QUERY_KEYS.DOCUMENTS, id], () =>
    getDocumentById(id),
  );

export const useDocumentSuppliesById = (id: string) =>
  useQuery<ScopePopup[]>([QUERY_KEYS.DOCUMENTS, id], () =>
    getDocumentSupplieById(id),
  );

export const useDocumentBills = (id: string) =>
  useQuery<any>([QUERY_KEYS.DOCUMENT_BILLS, id], () => getDocumentBills(id));

export const useApprovalDataById = (id: string) =>
  useQuery<ScopePopup[]>([QUERY_KEYS.APPROVAL, id], () =>
    getDocumentSupplieById(id),
  );
